import { template as template_9028bdd02a7b4b3a876d66615b7f9097 } from "@ember/template-compiler";
const FKControlMenuContainer = template_9028bdd02a7b4b3a876d66615b7f9097(`
  <li class="form-kit__control-menu-container">
    {{yield}}
  </li>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default FKControlMenuContainer;
